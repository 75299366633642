<template>
  <div class="programDetails">
    <!-- 作者信息 -->
    <div class="programDetails_top">
      <div class="programDetails_topUser">
        <div class="programDetails_topUserImg">
          <img :src="planMainInfo.picture" />
        </div>
        <div class="programDetails_topUserInfo">
          <div>{{ planMainInfo.username }}</div>
          <div>策划师</div>
        </div>
      </div>
      <div class="programDetails_topTitle">{{ planMainInfo.title }}</div>
      <div class="programDetails_topData">
        <!-- 观看 -->
        <div>
          <div>
            <i class="el-icon-view"></i>
          </div>
          <div>{{ visitNumber }}</div>
        </div>
        <!-- 点赞 -->
        <div>
          <div>
            <i class="iconfont icondianzan"></i>
          </div>
          <div>{{ planMainInfo.like_num }}</div>
        </div>
      </div>
    </div>
    <!-- PPT内容 -->
    <div class="programDetails_main">
      <div
        @click="openModal(index)"
        class="programDetails_mainItem"
        v-for="(item, index) in planMainContent"
        :key="index"
      >
        <img class="programDetails_mainImg" :src="item.img_url" />
      </div>
    </div>
    <!-- 点赞加收藏 -->
    <div class="programDetails_handle">
      <div @click="like()" :style="islikeStatus ? 'color: #F1DC64;' : ''">
        <div>
          <i class="iconfont icondianzan"></i>
        </div>
        <div>赞（{{ planMainInfo.like_num }}）</div>
      </div>
      <div @click="favorites()">
        <div>
          <i v-if="!iscollStatus" class="el-icon-star-off"></i>
          <i v-else class="el-icon-star-on"></i>
        </div>
        <div>收藏</div>
      </div>
    </div>
    <!-- 评论 -->
    <div class="programDetails_comment">
      <div>
        <el-input
          size="mini"
          placeholder="请输入内容"
          v-model="commentInput"
        ></el-input>
      </div>
      <div @click="commentBtn()">
        <i class="el-icon-s-promotion"></i>
      </div>
    </div>
    <!-- 评论显示模块 -->
    <div class="programDetails_commentReveal">
      <div class="programDetails_commentReveal_num">
        <div>全部评论{{ comment.length }}条</div>
      </div>
      <div
        class="programDetails_commentReveal_content"
        v-for="(item, index) in comment"
        :key="index"
      >
        <div class="programDetails_commentReveal_contentImg">
          <img :src="item.pic" />
        </div>
        <div class="programDetails_commentReveal_contentInfo">
          <div>{{ item.username }}</div>
          <div>{{ item.content }}</div>
        </div>
      </div>
    </div>
    <!-- 图片放大模态框 -->
    <div class="programDetails_enlarge" :style="`display: ${srcList_status}`" @touchmove.prevent.stop>
      <!-- 遮罩层 -->
      <!-- <div class="programDetails_enlarge_maskLayer"></div> -->
      <!-- 内容层 -->
      <div class="programDetails_content">
        <!-- 头部 -->
        <div class="programDetails_content_top">
          <div>
            <i style="color: #000" class="el-icon-close"></i>
          </div>
          <div>{{ srcListIndex + 1 }}/{{ srcList.length }}</div>
          <div @click="closeModal">
            <i class="el-icon-close"></i>
          </div>
        </div>
        <!-- 内容 -->
        <div
          class="programDetails_content_main"
          @touchstart.prevent.stop="startSlideImg"
          @touchend.prevent.stop="endSlideImg"
        >
          <div :style="`transform: translateX(-${srcListIndex}00vw)`">
            <div v-for="(item, index) in srcList" :key="index">
              <img :src="item.img_url" />
            </div>
          </div>
        </div>
        <!-- 尾部 -->
        <div class="programDetails_content_footer">
          <!-- <div><i class="el-icon-minus"></i></div>
          <div>还原</div>
          <div>关闭</div>
          <div><i class="el-icon-plus"></i></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 主要信息
      planMainInfo: "",
      // 评论
      comment: "",
      // PPT内容
      planMainContent: [],
      // 收藏状态
      iscollStatus: true,
      // 点赞状态
      islikeStatus: true,
      // 评论输入框
      commentInput: "",
      // 放大图片的模态框状态
      srcList_status: "none",
      // 放大后的图片
      srcList: [],
      // 滑动下标
      srcListIndex: 0,
      // 开始坐标
      startX: "",
      // 结束坐标
      endX: "",

      visitNumber: "0",
    };
  },
  created() {
    let visit = this.$route.query.visit
    if (visit) {
      this.visitNumber = visit
    }
    this.getHomeDetails();
  },
  methods: {
    // 获取详情
    getHomeDetails() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/details",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.planMainInfo = res.data.data.main;
          this.comment = res.data.data.comment;

          this.planMainContent = res.data.data.view_imgs;
          this.srcList = res.data.data.view_imgs;
          this.iscollStatus = res.data.data.iscoll;
          this.islikeStatus = res.data.data.islike;
        }
      });
    },
    // 收藏
    favorites() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/collection",
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
          cat: 2,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.iscollStatus = !this.iscollStatus;
        }
      });
    },
    // 点赞
    like() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/collection",
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          item_id: id,
          cat: 1,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.islikeStatus = !this.islikeStatus;
          this.getHomeDetails();
        }
      });
    },
    // 评论
    commentBtn() {
      let id = this.$route.query.id;
      this.$axios({
        url: this.$api + "/auth/ppt/comment/insert",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8;",
        },
        data: {
          comment: this.commentInput,
          item_id: id,
        },
      }).then((res) => {
        this.commentInput = "";
        this.getHomeDetails();
      });
    },
    // 滑动开始坐标
    startSlideImg(e) {
      this.startX = e.changedTouches[0].pageX;
    },
    // 滑动结束坐标
    endSlideImg(e) {
      this.end_x = e.changedTouches[0].pageX;
      if (this.startX > this.end_x) {
        if (this.srcList.length - 1 != this.srcListIndex) {
          console.log("左滑");
          this.srcListIndex += 1;
        }
      }
      if (this.startX < this.end_x) {
        if (this.srcListIndex != 0) {
          console.log("右滑");
          this.srcListIndex -= 1;
        }
      }
    },
    // 打开模态框
    openModal(e) {
      this.srcListIndex = e;
      this.srcList_status = "";
    },
    // 关闭模态框
    closeModal() {
      this.srcList_status = "none";
    },
  },
};
</script>

<style scoped>
.programDetails {
  background-color: #fff;
  margin-bottom: 15rem;
}
/* 作者信息 */
.programDetails_top {
  margin: 0.5rem;
}
.programDetails_topUser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
}
.programDetails_topUserImg {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  overflow: hidden;
}
.programDetails_topUserImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.programDetails_topUserInfo {
  font-size: 16px;
  margin-left: 0.5rem;
}
.programDetails_topUserInfo > div:nth-of-type(2) {
  margin-top: 0.3rem;
  color: #666;
  font-size: 10px;
}
.programDetails_topTitle {
  padding: 0.5rem;
  font-size: 14px;
  font-weight: 600;
}
.programDetails_topData {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.programDetails_topData > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 0.5rem;
}
.programDetails_topData > div i {
  font-size: 20px;
  color: #666;
  margin: 0 0.5rem;
}
/* PPT内容 */
.programDetails_mainItem {
  width: 100%;
  height: 100%;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.programDetails_mainImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 点赞收藏 */
.programDetails_handle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0.5rem 1rem;
}
.programDetails_handle > div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 0 0.5rem;
}
.programDetails_handle > div i {
  font-size: 20px;
  margin: 0.5rem;
}
/* 评论 */
.programDetails_comment {
  display: flex;
  margin: 0 0.5rem;
  justify-content: space-between;
}
.programDetails_comment > div:nth-of-type(1) {
  width: 90%;
}
.programDetails_comment > div:nth-of-type(2) {
  width: 18%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e3e3e3;
}
.programDetails_comment > div:nth-of-type(2):active {
  background-color: #e3e3e3;
  border-radius: 5px;
}
/* 评论显示模块 */
.programDetails_commentReveal_num {
  margin: 1rem 0;
}
.programDetails_commentReveal {
  padding: 1rem;
}
.programDetails_commentReveal_content {
  display: flex;
  margin: 0.5rem;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;
}
.programDetails_commentReveal_contentImg {
  width: 2.3rem;
  height: 2rem;
  border-radius: 100%;
  overflow: hidden;
}
.programDetails_commentReveal_contentImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.programDetails_commentReveal_contentInfo {
  width: 90%;
  font-size: 14px;
  padding: 0 20px;
}
.programDetails_commentReveal_contentInfo > div:nth-of-type(1) {
  font-size: 16px;
}
.programDetails_commentReveal_contentInfo > div:nth-of-type(2) {
  font-size: 14px;
  color: #7c7c7c;
  margin: 0.5rem 0;
}

/* 图片放大弹窗 */
.programDetails_enlarge {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.programDetails_content {
  display: flex;
  background-color: #000;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  color: #fff;
}
.programDetails_content i {
  color: #fff;
  font-size: 14px;
}
.programDetails_content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  font-size: 14px;
}
.programDetails_content_top > div:nth-of-type(1) {
  width: 25px;
  height: 25px;
}
.programDetails_content_top > div:nth-of-type(2) {
  background-color: #666;
  padding: 3px 7px;
  border-radius: 15px;
}
.programDetails_content_top > div:nth-of-type(3) {
  margin-right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #666;
}
.programDetails_content_main {
  width: 100vw;
  height: 35vh;
}
.programDetails_content_main > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.5s;
}
.programDetails_content_main > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.programDetails_content_main img {
  width: 100vw;
  height: 100%;
  object-fit: cover;
}
.programDetails_content_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  font-size: 14px;
}
.programDetails_content_footer > div {
  background-color: #666;
  margin: 0 10px;
  padding: 0 10px;
  border-radius: 15px;
}
</style>